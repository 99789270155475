import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { ExportExcel } from '../../components/exportExcel/exportExcel.component';
import { loadGoals, loadResults } from '../../api/common';
import { ResultDataInterface, ReportInterface } from '../../interfaces';
import { currentReportsDate, dateFormat } from '../../constants';
import { muiDatePickerTheme } from '../../theme';

import { loadReports } from './reports.api';
import { Container, ReportTable, PickerWrapper } from './reports.styled';

export const Reports = () => {
  const { t } = useTranslation();
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const [goals, setGoals] = useState<ResultDataInterface>();
  const [results, setResults] = useState<ResultDataInterface>();
  const [chosenDate, setChosenDate] = useState(
    dayjs(new Date().setHours(0, 0, 0, 0)),
  );

  useEffect(() => {
    loadReports(chosenDate, (reportsArray) => {
      setReports(reportsArray);
      loadResults(chosenDate, setResults);
    });
    loadGoals(chosenDate, setGoals);
  }, [chosenDate]);

  const handleDateChange = (newValue: unknown) => {
    const newChosenDate = newValue || dayjs(new Date().setHours(0, 0, 0, 0));
    setChosenDate(newChosenDate as Dayjs);
  };

  return (
    <Container>
      <Typography variant="h4">{t('reports.reports')}</Typography>
      <PickerWrapper>
        <ThemeProvider theme={muiDatePickerTheme}>
          <DesktopDatePicker
            label={t('reports.chooseDate')}
            format={dateFormat}
            minDate={dayjs.unix(currentReportsDate)}
            value={chosenDate}
            onChange={handleDateChange}
            disableFuture
            maxDate={dayjs()}
            slotProps={{
              textField: {
                InputProps: {
                  onKeyDown: (e) => e.preventDefault(),
                  disabled: true,
                },
              },
            }}
          />
        </ThemeProvider>
      </PickerWrapper>
      <ReportTable
        reports={reports}
        chosenDate={chosenDate}
        goals={goals}
        results={results}
      />
      <ExportExcel reports={reports} chosenDate={chosenDate} />
    </Container>
  );
};
