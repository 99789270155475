import ExcelJS from 'exceljs';
import { useTranslation } from 'react-i18next';

import { reportsExcelDateFormat, summaryExcelDateFormat } from '../../constants';

import { createColumnsTemplateData, createSumRowData, downloadExcelFile } from './exportExcel.utils';
import { StyledButton } from './exportExcel.styled';
import { ExportExcelProps } from './exportExcel.types';

export const ExportExcel = ({ reports, summary, chosenDate }: ExportExcelProps) => {
  const { t } = useTranslation();
  const todayReports = reports?.filter((report) => report.timestamp > chosenDate.unix());
  const data = todayReports || summary || [];
  const excelDate = chosenDate.format(
    todayReports ? reportsExcelDateFormat : summaryExcelDateFormat,
  );

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(excelDate);

    sheet.columns = createColumnsTemplateData();

    data.forEach((rowData) => {
      sheet.addRow(rowData).alignment = { horizontal: 'center' };
    });

    sheet.addRow(createSumRowData(data)).alignment = { horizontal: 'center' };

    sheet.getRow(1).font = { bold: true };
    sheet.getRow(1).alignment = { horizontal: 'center' };
    sheet.getRow(data.length + 2).font = { bold: true };

    sheet.getRow(data.length + 2).border = {
      top: { style: 'thick' },
    };

    sheet.getColumn(1).border = {
      right: { style: 'thick' },
    };

    sheet.getCell(data.length + 2, 1).border = {
      top: { style: 'thick' },
      right: { style: 'thick' },
    };

    workbook.xlsx.writeBuffer().then((excelData) => downloadExcelFile(excelData, excelDate));
  };

  if (!data.length) return null;

  return (
    <StyledButton
      onClick={exportExcelFile}
      variant="contained"
      color="success"
    >
      {t('exportExcel.export')}
    </StyledButton>
  );
};
