import { useState, MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, IconButton, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { AuthContext } from '../../contexts/auth/auth.context';
import { UserRole, Path } from '../../enums';
import { UserStorageKey } from '../app.constants';

import LogoImg from './images/logo.png';
import {
  LogoLink,
  Logo,
  StyledToolbar,
  MenuLink,
  StyledMenuItem,
  Separator,
} from './navbar.styled';

export const Navbar = () => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hasMoreOptions = user?.role === UserRole.Moderator || user?.role === UserRole.Superadmin;

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(UserStorageKey);
    handleCloseMenu();
    setUser(null);
  };

  return (
    <AppBar position="sticky">
      <StyledToolbar>
        <LogoLink to={Path.Home}>
          <Logo src={LogoImg} />
        </LogoLink>
        {!!user && (
          <>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={handleOpenMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              open={!!anchorEl}
              onClose={handleCloseMenu}
              anchorEl={anchorEl}
            >
              <MenuLink to={Path.AddReport}>
                <StyledMenuItem onClick={handleCloseMenu}>{t('navbar.addReport')}</StyledMenuItem>
              </MenuLink>
              <Separator />
              <MenuLink to={Path.Reports}>
                <StyledMenuItem onClick={handleCloseMenu}>{t('navbar.reports')}</StyledMenuItem>
              </MenuLink>
              <Separator />
              {hasMoreOptions && (
                <MenuLink to={Path.Summary}>
                  <StyledMenuItem onClick={handleCloseMenu}>{t('navbar.summary')}</StyledMenuItem>
                </MenuLink>
              )}
              {hasMoreOptions && <Separator />}
              {hasMoreOptions && (
                <MenuLink to={Path.EditReports}>
                  <StyledMenuItem onClick={handleCloseMenu}>{t('navbar.editReports')}</StyledMenuItem>
                </MenuLink>
              )}
              {hasMoreOptions && <Separator />}
              {hasMoreOptions && (
                <MenuLink to={Path.Goals}>
                  <StyledMenuItem onClick={handleCloseMenu}>{t('navbar.goals')}</StyledMenuItem>
                </MenuLink>
              )}
              {hasMoreOptions && <Separator />}
              {hasMoreOptions && (
                <MenuLink to={Path.Users}>
                  <StyledMenuItem onClick={handleCloseMenu}>{t('navbar.users')}</StyledMenuItem>
                </MenuLink>
              )}
              {hasMoreOptions && <Separator />}
              <StyledMenuItem onClick={handleLogout}>{t('navbar.logout')}</StyledMenuItem>
            </Menu>
          </>
        )}
      </StyledToolbar>
    </AppBar>
  );
};
