import styled from 'styled-components';

import { SummaryTable as TableBase } from '../../components/summaryTable/summaryTable.component';

export const Container = styled.div``;

export const SummaryTable = styled(TableBase)`
  margin-top: 20px;
`;

export const PickerWrapper = styled.div`
  margin-top: 30px;
`;
