import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth/auth.context';
import { ExportExcel } from '../../components/exportExcel/exportExcel.component';
import { loadGoals, loadResults } from '../../api/common';
import { PoiResultInterface, ResultDataInterface } from '../../interfaces';
import { currentReportsDate, monthFormat } from '../../constants';
import { muiDatePickerTheme } from '../../theme';
import { Path, UserRole } from '../../enums';

import { Container, PickerWrapper, SummaryTable } from './summary.styled';

export const Summary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [goals, setGoals] = useState<ResultDataInterface>();
  const [results, setResults] = useState<PoiResultInterface[]>([]);
  const [chosenDate, setChosenDate] = useState(
    dayjs(new Date().setHours(0, 0, 0, 0)),
  );
  const canSeeSummary = user?.role === UserRole.Moderator || user?.role === UserRole.Superadmin;

  useEffect(() => {
    if (canSeeSummary) {
      loadResults(
        chosenDate,
        (newResults) => setResults(
          Object.values(newResults).sort((a, b) => (a.poiId < b.poiId ? -1 : 1)),
        ),
      );
      loadGoals(chosenDate, setGoals);
    } else {
      navigate(Path.Home);
    }
  }, [chosenDate]);

  const handleDateChange = (newValue: unknown) => {
    const newChosenDate = newValue || dayjs(new Date().setHours(0, 0, 0, 0));
    setChosenDate(newChosenDate as Dayjs);
  };

  return (
    <Container>
      <Typography variant="h4">{t('summary.summary')}</Typography>
      <PickerWrapper>
        <ThemeProvider theme={muiDatePickerTheme}>
          <DesktopDatePicker
            label={t('summary.chooseDate')}
            format={monthFormat}
            minDate={dayjs.unix(currentReportsDate)}
            value={chosenDate}
            onChange={handleDateChange}
            disableFuture
            maxDate={dayjs()}
            slotProps={{
              textField: {
                InputProps: {
                  onKeyDown: (e) => e.preventDefault(),
                  disabled: true,
                },
              },
            }}
            views={['month', 'year']}
          />
        </ThemeProvider>
      </PickerWrapper>
      <SummaryTable goals={goals} results={results} />
      <ExportExcel summary={results} chosenDate={chosenDate} />
    </Container>
  );
};
