import { useTranslation } from 'react-i18next';

import { ReportValuesKeys } from '../../constants';
import { Error } from '../../theme';

import { SummaryTableProps } from './summaryTable.types';
import { Container, TableWrapper, Table } from './summaryTable.styled';

export const SummaryTable = ({
  className,
  results,
  goals = {},
}: SummaryTableProps) => {
  const { t } = useTranslation();

  if (!results.length) {
    return <Error>{t('summary.noSummary')}</Error>;
  }

  return (
    <Container className={className}>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>{t('summary.poi')}</th>
              {ReportValuesKeys.map((key) => (
                <th key={key}>{t(`summary.${key}`)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {results.map((value) => {
              const poiId = value.poiId.toLowerCase();
              const poiGoal = goals[poiId];

              return (
                <tr key={value.poiId}>
                  <td>{value.poiId}</td>
                  {ReportValuesKeys.map((key, index) => (
                    <td
                      key={key}
                      className={`
                        ${poiGoal?.[key] && (value[key] ?? 0) >= poiGoal[key] ? 'green' : ''}
                        ${index < 4 && poiGoal?.[key] && (value[key] ?? 0) < poiGoal[key] ? 'red' : ''}
                      `}
                    >
                      {value[key]}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>{results.length}</td>
              {ReportValuesKeys.map((key) => (
                <td key={key}>
                  {results.reduce((acc, report) => acc + (report[key] ?? 0), 0)}
                </td>
              ))}
            </tr>
          </tfoot>
        </Table>
      </TableWrapper>
    </Container>
  );
};
