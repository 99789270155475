export enum Path {
  Home = '/',
  Login = '/login',
  Reports = '/reports',
  Summary = '/summary',
  AddReport = '/add-report',
  EditReports = '/edit-reports',
  Goals = '/goals',
  Users = '/users',
}
