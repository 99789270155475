import styled from 'styled-components';

import { Color } from '../../theme';

export const Container = styled.div`
  padding-top: 10px;
  margin-bottom: 5px;
`;

export const TableWrapper = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const Table = styled.table`
  border: 1px solid ${Color.Black};
  border-left: none;
  border-right: none;
  border-collapse: collapse;
  text-align: center;
  width: 100%;

  th, td {
    border: 1px solid ${Color.Black};
    border-collapse: collapse;
    padding: 2px;
  }

  th:first-of-type, td:first-of-type {
    border-right-width: 3px;
    border-left: none;
  }

  th:last-of-type, td:last-of-type {
    border-right: none;
  }

  .green {
    background-color: ${Color.LightGreen};
  }

  .red {
    background-color: ${Color.LightRed};
  }

  tfoot {
    font-weight: bold;
    
    td {
      border-top-width: 3px;
    }
  }
`;
